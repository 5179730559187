@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    /* Disables pull-to-refresh but allows overscroll glow effects. */
    overscroll-behavior-y: contain;
    overflow-y: hidden;
    height: 100%;
}

@layer utilities {
    .animation-delay-200 {
        animation-delay: 0.2s;
    }

    .animation-delay-400 {
        animation-delay: 0.4s;
    }
}